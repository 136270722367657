import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Select, Store } from '@ngxs/store'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { combineLatest, throwError } from 'rxjs'
import { Observable } from 'rxjs-compat'
import { catchError, finalize } from 'rxjs/operators'
import { IUpdateDocumentPayment } from 'src/app/models/request/IUpdateDocumentPayment'
import { IValidatePayment } from 'src/app/models/request/IValidatePayment'
import { PaymentService } from 'src/app/services/pages/payment.service'
import { PlanState } from 'src/app/store/plan/plan.state'
import { PAYMENT_STEPS } from 'src/app/utils/constants/payment'
import { IMyPayment } from './../../models/response/IMyPayment'
import { IPlan } from './../../models/response/IPlan'
import { IProcessInfoPayment } from './../../models/response/IProcessInfoPayment'
import { IResponse } from './../../models/response/IResponse'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../utils/constants/generic-messages'
import {
  ERROR_TOKEN_PAYMENT_MESSAGE,
  ERROR_TOKEN_PAYMENT_TITLE,
} from './../../utils/constants/payment-messages'
import {
  INFO_NO_FILE_AVAILABLE_MESSAGE,
  INFO_NO_FILE_AVAILABLE_TITLE,
  SUCCESS_DOWNLOAD_PAYMENT_VOUCHER_MESSAGE,
  SUCCESS_DOWNLOAD_VOUCHER_TITLE,
} from './../../utils/constants/profile'
import { downloadBase64File } from './../../utils/converts/file'
import { UserFacade } from './../user/user.facade'
import {
  UpdateCompanyInformation,
  UpdateIsLoadingDocument,
  UpdateIsLoadingMyPayments,
  UpdateIsLoadingPaymentForm,
  UpdateIsLoadingProcessPayment,
  UpdateIsValidForm,
  UpdateMethodPayment,
  UpdateMyPayments,
  UpdatePersonalInformation,
  UpdateStepById,
  UpdateSteps,
} from './payment.actions'
import {
  ICompanyInformation,
  IPersonalInformation,
  IStepPaymentForm,
  PaymentState,
} from './payment.state'

@Injectable({ providedIn: 'root' })
export class PaymentFacade {
  @Select(PaymentState.getIsValidFormPayment) isValidForm$: Observable<boolean>
  @Select(PaymentState.getSteps) steps$: Observable<IStepPaymentForm[]>
  @Select(PaymentState.getPersonalInformation)
  personalInformation$: Observable<IPersonalInformation>
  @Select(PaymentState.getCompanyInformation)
  companyInformation$: Observable<ICompanyInformation>
  @Select(PaymentState.getMethodPayment) methodPayment$: Observable<number>
  @Select(PaymentState.getMyPayments) myPayments$: Observable<IMyPayment[]>
  @Select(PaymentState.getIsLoadingPaymentForm)
  isLoadingPaymentForm$: Observable<boolean>
  @Select(PaymentState.getIsLoadingProcessPayment)
  isLoadingProcessPayment$: Observable<boolean>
  @Select(PaymentState.getIsLoadingMyPayments)
  isLoadingMyPayments$: Observable<boolean>
  @Select(PlanState.getSelectedPlan)
  planSelected$: Observable<IPlan>

  public infoPayment: IProcessInfoPayment
  public isSuccessPayment: boolean

  constructor(
    private router: Router,
    private notification: NzNotificationService,
    private store: Store,
    private userFacade: UserFacade,
    private _payment: PaymentService
  ) {
    this.isSuccessPayment = true
  }

  public updateIsValidForm(value: boolean): void {
    this.store.dispatch(new UpdateIsValidForm(value))
  }

  public UpdateStepById(id: string, name: string, active: boolean): void {
    this.store.dispatch(new UpdateStepById(id, name, active))
  }

  public updatePersonalInformation(value: IPersonalInformation): void {
    this.store.dispatch(new UpdatePersonalInformation(value))
  }

  public updateCompanyInformation(value: ICompanyInformation): void {
    this.store.dispatch(new UpdateCompanyInformation(value))
  }

  public updateMethodPayment(value: string): void {
    this.store.dispatch(new UpdateMethodPayment(value))
  }
  public updateMyPayments(value: any): void {
    this.store.dispatch(new UpdateMyPayments(value))
  }

  public async payPlan(typeSuscription: number) {
    this.store.dispatch(new UpdateIsLoadingPaymentForm(true))
    combineLatest([
      this.userFacade.user$,
      this.personalInformation$,
      this.companyInformation$,
      this.planSelected$,
    ]).subscribe(
      ([user, personalInformation, companyInformation, planSelected]) => {
        const { name, address, email, country, city, postalCode } =
          personalInformation
        const { rut, nameBusiness, secondaryEmail, scopeBusiness } =
          companyInformation

        const paymentRequest = {
          planId: planSelected.planId,
          typeSuscriptionId: +typeSuscription + 1,
          informationBilling: {
            name,
            address,
            email,
            country,
            city,
            postalCode,
            rut,
            nameBusiness,
            secondaryEmail,
            scopeBusiness,
          },
        }

        this._payment
          .paymentPlan(paymentRequest)
          .pipe(
            catchError((error: Error) => {
              this.notification.create(
                'error',
                ERROR_SERVICE_TITLE,
                ERROR_SERVICE_MESSAGE
              )
              return throwError(error)
            }),
            finalize(() => {
              this.store.dispatch(new UpdateIsLoadingPaymentForm(false))
            })
          )
          .subscribe((res: IResponse) => {
            if (res.status.code === 201) {
              window.location.assign(res.data.url)
            } else {
              this.notification.create(
                'warning',
                ERROR_TOKEN_PAYMENT_MESSAGE,
                ERROR_TOKEN_PAYMENT_TITLE
              )
            }
          })
      }
    )
  }

  public validatePayment(request: IValidatePayment) {
    this.store.dispatch(new UpdateIsLoadingProcessPayment(true))
    this._payment.validatePayment(request).subscribe(
      (response: IResponse) => {
        this.store.dispatch(new UpdateIsLoadingProcessPayment(false))

        switch (response.status.code) {
          case 201:
            this.isSuccessPayment = true
            this.infoPayment = response.data

            break
          default:
            this.isSuccessPayment = false
            break
        }
      },
      (error) => {
        this.isSuccessPayment = false
        this.store.dispatch(new UpdateIsLoadingProcessPayment(false))
      }
    )
  }

  public setStepsDefault() {
    this.store.dispatch(new UpdateSteps(PAYMENT_STEPS))
  }

  public getMyPayments() {
    this.store.dispatch(new UpdateIsLoadingMyPayments(true))
    this._payment.getMyPayments().subscribe(
      (response: IResponse) => {
        this.store.dispatch(new UpdateIsLoadingMyPayments(false))
        switch (response.status.code) {
          case 200:
            this.store.dispatch(new UpdateMyPayments(response.data))
            return response.data.length
          default:
            return 0
        }
      },
      (error: Error) => {
        this.store.dispatch(new UpdateIsLoadingMyPayments(false))
        return 0
      }
    )
  }

  public getDocumentFetch(paymentId: number | string, documentTypeId: number) {
    this.store.dispatch(new UpdateIsLoadingDocument(true))
    this._payment.getDocument(paymentId, documentTypeId).subscribe(
      (response: IResponse) => {
        this.store.dispatch(new UpdateIsLoadingDocument(false))
        const docName = `Comprobante_ORD${paymentId}_empati.pdf`
        switch (response.status.code) {
          case 200:
            downloadBase64File(response.data, docName)
            this.notification.create(
              'success',
              SUCCESS_DOWNLOAD_VOUCHER_TITLE,
              SUCCESS_DOWNLOAD_PAYMENT_VOUCHER_MESSAGE
            )
            break
          default:
            break
        }
      },
      (error: Error) => {
        this.notification.create(
          'success',
          INFO_NO_FILE_AVAILABLE_TITLE,
          INFO_NO_FILE_AVAILABLE_MESSAGE
        )
        this.store.dispatch(new UpdateIsLoadingDocument(false))
      }
    )
  }

  public updateDocumentFetch(
    request: IUpdateDocumentPayment
  ): Promise<IResponse> {
    return new Promise((resolve, reject) => {
      this.store.dispatch(new UpdateIsLoadingDocument(true))
      this._payment.updateDocument(request).subscribe(
        (response: IResponse) => {
          this.store.dispatch(new UpdateIsLoadingDocument(false))
          if (response.status.code === 200 || response.status.code === 201) {
            resolve(response)
          } else {
            reject(response)
          }
        },
        (error) => {
          this.store.dispatch(new UpdateIsLoadingDocument(false))
          this.notification.create(
            'error',
            'Error al generar el documento',
            'Ha ocurrido un error al intentar actualizar el documento.'
          )
          reject(error)
        }
      )
    })
  }
}

import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { IPlan } from 'src/app/models/response/IPlan'
import { IResponse } from 'src/app/models/response/IResponse'
import { environment } from 'src/environments/environment'

@Injectable({ providedIn: 'root' })
export class PlanService {
  protected api: string
  public infoPlan: IPlan

  constructor(public http: HttpClient) {
    this.api = environment.api
  }

  public getPlan(): Observable<IResponse> {
    const uri = `${this.api}/plan/user`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getPlans(): Observable<IResponse> {
    const uri = `${this.api}/v1/public/plan`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }

  public getPlanDetail(
    planId: string,
    typeSuscription: number
  ): Observable<IResponse> {
    const uri = `${this.api}/plan/${planId}/${typeSuscription}`
    return this.http.get(uri).pipe(map((data: IResponse) => data))
  }
}

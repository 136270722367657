import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from '@angular/core'
import { SurveyFacade } from './../../../store/survey/survey.facade'

@Component({
  selector: 'app-header-section-survey',
  templateUrl: './header-section-survey.component.html',
  styleUrls: ['./header-section-survey.component.scss'],
})
export class HeaderSectionSurveyComponent implements OnChanges {
  @Output() isModeSurveyArchivedChange = new EventEmitter<boolean>()
  @Output() isVisibleFormWithIAChange = new EventEmitter<boolean>()
  @Output() surveyTypeFilterChange = new EventEmitter<string>()
  @Input() isModeSurveyArchived: boolean = false
  @Input() isVisibleFormWithIA: boolean = false

  public surveyTypeFilter: string = 'ALL'
  public isVisibleForm: boolean = false

  constructor(
    public surveyFacade: SurveyFacade,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isModeSurveyArchived) {
      if (changes.isModeSurveyArchived.currentValue === false) {
        this.surveyTypeFilter = 'ALL'
        this.surveyTypeFilterChange.emit(this.surveyTypeFilter)
      }
    }
  }

  filterSurveys(filterType: string = 'ALL'): void {
    this.surveyTypeFilter = filterType
    this.surveyTypeFilterChange.emit(filterType)

    switch (filterType) {
      case 'ALL':
      case 'FAVORITE':
        this.isModeSurveyArchived = false
        this.isModeSurveyArchivedChange.emit(this.isModeSurveyArchived)
        this.surveyFacade.getSurveyList(this.isModeSurveyArchived)
        break
      case 'ARCHIVED':
        this.isModeSurveyArchived = true
        this.isModeSurveyArchivedChange.emit(this.isModeSurveyArchived)
        this.surveyFacade.getSurveyList(this.isModeSurveyArchived)
        break
      default:
        this.surveyTypeFilter = 'ALL'
        break
    }
  }

  showModal(): void {
    this.isVisibleForm = true
  }

  handleOk(): void {
    this.isVisibleForm = false
  }

  handleCancel(): void {
    this.isVisibleForm = false
  }

  handleModal(): void {
    this.isVisibleForm = false
    this.createSurveyIA()
  }

  showModalIA(isVisible: boolean): void {
    this.isVisibleFormWithIA = isVisible
    if (!isVisible) this.isVisibleFormWithIAChange.emit(false)
  }

  createSurveyIA(): void {
    this.isVisibleFormWithIA = true
    console.log('createSurveyIA')

    setTimeout(() => {
      this.updateModalDOM()
    }, 500)
  }

  updateModalDOM(): void {
    const elemento =
      this.el.nativeElement.ownerDocument.querySelector('.ant-modal-content')

    if (elemento) {
      this.renderer.addClass(elemento, 'outline-gradient-1')
    }
  }
}

<nz-skeleton
  [nzLoading]="(planFacade.isLoadingPlansDetail$ | async) === true"
  [nzActive]="true"
  [nzAvatar]="true"
  [nzParagraph]="{ rows: 10 }"
  [nzRound]="true"
>
  <div
    class=""
    *ngIf="
      (planFacade.isLoadingPlansDetail$ | async) === false &&
        planFacade.plansDetail$ | async
    "
  >
    <div class="plan-types">
      <section>
        <app-card-info-plans></app-card-info-plans>
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="price-heading">
                <h1>Elige el plan más conveniente para ti</h1>
                <nz-segmented
                  class="mt-5"
                  [nzOptions]="options"
                  [ngModel]="optionSelected"
                  (ngModelChange)="changeOption($event)"
                  nzSize="large"
                ></nz-segmented>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div
              *ngFor="
                let plan of planFacade.plansDetail$
                  | async
                  | filterPlans: optionSelected
              "
              class="col-12 col-sm-6 col-lg-4 col-xl-4 col-xxl-3 mt-5"
            >
              <div
                class="generic-content"
                [ngClass]="{ active: plan.name === 'Plus' }"
              >
                <div class="generic-head-price">
                  <div class="generic-head-content">
                    <div class="head-bg"></div>
                    <div class="head">
                      <span>{{ plan.name | uppercase }}</span>
                    </div>
                  </div>
                  <div class="generic-price-tag">
                    <span class="price">
                      <span class="sign">$</span>
                      <span *ngIf="optionSelected === 0" class="currency">{{
                        plan.monthlyPrice | number: '':'es-CL'
                      }}</span>
                      <!-- <span *ngIf="optionSelected === 1" class="currency">{{
                        plan.quarterlyPrice | number : '' : 'es-CL'
                      }}</span>
                      <span *ngIf="optionSelected === 2" class="currency">{{
                        plan.semiAnualPrice | number : '' : 'es-CL'
                      }}</span> -->
                      <span *ngIf="optionSelected === 1" class="currency">{{
                        plan.anualPrice | number: '':'es-CL'
                      }}</span>
                      <span *ngIf="plan.name !== 'Free'" class="ml-2 small"
                        >(IVA inc.)</span
                      >
                      <span class="month d-block text-center">{{
                        optionSelected === 0 ? 'Mensual' : 'Anual'
                      }}</span>
                    </span>
                  </div>
                </div>

                <div
                  *ngFor="let characteristic of plan.plansDetail"
                  class="generic-feature-list"
                >
                  <ul class="px-3">
                    <li>
                      {{ characteristic.title }}
                      <span class="float-right">{{
                        characteristic.planValue === true ||
                        characteristic.planValue === false
                          ? characteristic.planValue === true
                            ? 'Si'
                            : 'No'
                          : characteristic.planValue
                      }}</span>
                    </li>
                  </ul>
                </div>

                <button
                  [disabled]="plan.name === 'Free'"
                  type="submit"
                  class="btn btn-lg btn-primary d-block my-5 mx-auto"
                  [routerLink]="'payment/' + plan.id + '/' + optionSelected"
                >
                  <span class="d-flex align-items-center">
                    {{ plan.name === 'Free' ? 'Gratis' : 'Suscríbete' }}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</nz-skeleton>

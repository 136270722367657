import { Injectable } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { IPlanDetail } from 'src/app/models/response/IPlanDetail'
import { PlanService } from 'src/app/services/service.index'
import { IResponse } from '../../models/response/IResponse'
import { UpdateActiveURL } from '../session/session.actions'
import { SessionState } from '../session/session.state'
import { IPlan } from './../../models/response/IPlan'
import {
  UpdateCurrentPlan,
  UpdateErrorCurrentPlan,
  UpdateLoadingCurrentPlan,
  UpdateLoadingPlan,
  UpdateLoadingPlansDetail,
  UpdatePlansDetail,
  UpdateSelectedPlan,
} from './plan.actions'
import { PlanState } from './plan.state'

@Injectable({ providedIn: 'root' })
export class PlanFacade {
  @Select(SessionState.getActiveURL) activeURL$: Observable<string>
  @Select(PlanState.getPlansDetail) plansDetail$: Observable<any>
  @Select(PlanState.getCurrentPlan) currentPlan$: Observable<IPlan>
  @Select(PlanState.getLoadingPlansDetail)
  isLoadingPlansDetail$: Observable<boolean>
  @Select(PlanState.getLoadingCurrentPlan)
  isLoadingCurrentPlan$: Observable<boolean>
  @Select(PlanState.getErrorCurrentPlan)
  isErrorCurrentPlan$: Observable<boolean>
  @Select(PlanState.getSelectedPlan)
  selectedPlan$: Observable<IPlan>

  constructor(private store: Store, private _plan: PlanService) {}

  public saveSelectedPlan(plan: IPlan): void {
    this.store.dispatch(new UpdateSelectedPlan(plan))
  }

  public obtainPlan(value: any): void {
    this.store.dispatch(new UpdateSelectedPlan(value))
  }

  public obtainPlanDetailFetch(planId: string, typeSuscription: number): void {
    this.store.dispatch(new UpdateLoadingPlan(true))
    this._plan.getPlanDetail(planId, typeSuscription).subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 201:
          case 200:
            this.store.dispatch(new UpdateSelectedPlan(response.data))
            break
        }
        this.store.dispatch(new UpdateLoadingPlan(false))
      },
      (error: Error) => {
        this.store.dispatch(new UpdateLoadingPlan(false))
      }
    )
  }

  public obtainPlansDetailFetch(): void {
    this.store.dispatch(new UpdateLoadingPlan(true))

    this.store.dispatch(new UpdateLoadingPlansDetail(true))
    this._plan.getPlans().subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 201:
          case 200:
            const { data } = response

            const plansDetail: IPlanDetail[] = data.map((item) => {
              return {
                ...item,
                id: item.planId,
                monthlyDiscount: item.monthlyDiscount
                  ? +item.monthlyDiscount
                  : 0,
              }
            })

            this.store.dispatch(new UpdatePlansDetail(plansDetail))
            break
        }
        this.store.dispatch(new UpdateLoadingPlansDetail(false))
        this.store.dispatch(new UpdateLoadingPlan(false))
      },
      (error: Error) => {
        this.store.dispatch(new UpdateLoadingPlan(false))
      }
    )
  }

  public obtainCurrentPlanFetch(): void {
    this.store.dispatch(new UpdateLoadingCurrentPlan(true))
    this.store.dispatch(new UpdateErrorCurrentPlan(false))

    this._plan.getPlan().subscribe(
      (response: IResponse) => {
        switch (response.status.code) {
          case 201:
          case 200:
            const { data } = response
            this.store.dispatch(new UpdateCurrentPlan(data))
            break
        }
        this.store.dispatch(new UpdateLoadingCurrentPlan(false))
        this.store.dispatch(new UpdateErrorCurrentPlan(false))
      },
      (error: Error) => {
        this.store.dispatch(new UpdateLoadingCurrentPlan(false))
        this.store.dispatch(new UpdateErrorCurrentPlan(true))
      }
    )
  }

  public updateActiveURL(activeURL: string): void {
    this.store.dispatch(new UpdateActiveURL(activeURL))
  }
}

import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import * as Highcharts from 'highcharts'
import { first } from 'rxjs/operators'
import { IEmailRecovery } from 'src/app/models/request/IEmailRecovery'
import { TokenService } from 'src/app/services/session/token.service'
import { ResponsesFacade } from 'src/app/store/responses/responses.facade'
import { SessionFacade } from 'src/app/store/session/session.facade'
import { QuestionFacade } from 'src/app/store/store.index'
import { environment } from './../../../environments/environment'
import { ISurvey } from './../../models/response/ISurvey'
import { ReportType } from './../../models/types/report.types'
import { DashboardFacade } from './../../store/dashboard/dashboard.facade'
import { SurveyFacade } from './../../store/survey/survey.facade'
import { UserFacade } from './../../store/user/user.facade'
import { blogDashboard } from './dataMock/blog'
import { npsIndicators } from './dataMock/charts'

const TITLE_SURVEY = 'Actividad Semanal de Encuestas Completadas'
const SUBTITLE_SURVEY =
  'Recuento total de encuestas completadas durante la última semana'
const TITLE_QUESTION = 'Actividad Semanal de Preguntas Respondidas'
const SUBTITLE_QUESTION =
  'Recuento total de preguntas respondidas durante la última semana'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  @ViewChild('scrollableDiv') public myScrollContainer: ElementRef

  Highcharts: typeof Highcharts = Highcharts

  public isBetaProduction = environment.production

  public formChart: FormGroup
  public selectedType: number
  private currenPage = 1
  public showLastResponsesOption = false
  public totalResponses = 0
  public npsResponsesIndicators: any = npsIndicators
  public blog: any = blogDashboard
  public slicedBlogs: any[][]
  public title: string
  public subtitle: string

  public IMG_NOT_FOUND = '../../../assets/images/icons/no-results.png'
  public typeReport: ReportType = 'surveys'
  public timeActivation: number = 60000
  public counterTime: number = 0

  constructor(
    private _token: TokenService,
    private sessionFacade: SessionFacade,
    public userFacade: UserFacade,
    public dashboardFacade: DashboardFacade,
    public surveyFacade: SurveyFacade,
    public questionFacade: QuestionFacade,
    public responsesFacade: ResponsesFacade,
    private fb: FormBuilder
  ) {
    this.questionFacade.obtainQuestionTypesFetch()
    this.dashboardFacade.getQuestionResponsesDataReportFetch()
    this.dashboardFacade.getWeekResponsesDataReportFetch()
    this.dashboardFacade.getTendencyWeeksReportFetch(1)
    this.responsesFacade.getLastResponses()

    this.formChart = this.fb.group({
      selectTypeQuestion: [1],
    })

    this.surveyFacade.surveyList$.subscribe((surveyList: ISurvey[]) => {
      if (surveyList?.length < 1) {
        this.surveyFacade.getSurveyList()
      }
    })
    this.changeTitleChart()
  }

  ngOnInit(): void {
    this.updateSlicedBlogs()

    setTimeout(() => {
      this._token.loadingPageInfo = false
    }, 1000)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateSlicedBlogs()
  }

  public async handleNotificationUserChange(): Promise<void> {
    const user = await this.userFacade.user$.pipe(first()).toPromise()

    const request: IEmailRecovery = {
      email: user.email,
    }
    this.sessionFacade.sendMailActivationAccountFetch(request)
    this.counterTime = this.timeActivation / 1000

    setInterval(() => {
      if (this.counterTime > 0) this.counterTime = this.counterTime - 1
      else clearInterval(this.counterTime)
    }, 1000)
  }

  private changeTitleChart(): void {
    this.title = this.typeReport === 'surveys' ? TITLE_SURVEY : TITLE_QUESTION
    this.subtitle =
      this.typeReport === 'surveys' ? SUBTITLE_SURVEY : SUBTITLE_QUESTION
  }

  updateSlicedBlogs() {
    const width = window.innerWidth

    if (width > 576) {
      this.slicedBlogs = []
      for (let i = 0; i < this.blog.length; i += 2) {
        this.slicedBlogs.push(this.blog.slice(i, i + 2))
      }
    } else {
      this.slicedBlogs = this.blog.map((item) => [item])
    }
  }

  onScroll() {
    if (this.myScrollContainer.nativeElement.scrollTop > 667) {
      this.showLastResponsesOption = true
      return
    }
    if (this.totalResponses > 10) this.showLastResponsesOption = false
  }

  scrollToEnd(): void {
    setTimeout(() => {
      this.myScrollContainer.nativeElement.scrollTo({
        top: this.myScrollContainer.nativeElement.scrollHeight,
        behavior: 'smooth',
      })
    }, 0)
  }

  scrollToTop(): void {
    this.responsesFacade
      .getLastResponses(1, 10)
      .then(() => {
        setTimeout(() => {
          this.myScrollContainer.nativeElement.scrollTo({
            top: 0,
            behavior: 'smooth',
          })
          this.showLastResponsesOption = false
        }, 100)
      })
      .catch((error) => {
        console.error('Error obteniendo las últimas respuestas:', error)
      })
  }

  public submitForm(type: any): void {
    this.dashboardFacade.getTendencyWeeksReportFetch(type)
  }

  public loadData(): void {
    this.responsesFacade.currentPage$.subscribe({
      next: (currentPage) => {
        this.currenPage = currentPage + 1
      },
    })
    this.responsesFacade
      .getLastResponses(this.currenPage)
      .then((responses) => {
        this.totalResponses = responses.lenght
        this.scrollToEnd()
      })
      .catch((error) => {
        return
      })
  }

  public async getWeekResponsesDataReport(event: ReportType): Promise<void> {
    this.typeReport = event
    this.dashboardFacade.getWeekResponsesDataReportFetch(null, this.typeReport)
  }
}

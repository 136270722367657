export const blogDashboard = [
  {
    id: 1,
    title: 'Entendiendo NPS (Net Promoter Score)',
    description:
      'Descubre cómo el Net Promoter Score puede transformar tu relación con los clientes.',
    url: 'https://www.empati.cl/',
  },
  {
    id: 2,
    title: 'Explorando el CSAT (Customer Satisfaction Score)',
    description:
      'Aprende cómo medir la satisfacción del cliente con Customer Satisfaction Score.',
    url: 'https://www.empati.cl/',
  },
  {
    id: 3,
    title: 'CES: Midiendo el Esfuerzo del Cliente',
    description:
      'Descubre cómo el Customer Effort Score es fundamental para mejorar la experiencia del cliente.',
    url: 'https://www.empati.cl/',
  },
  {
    id: 4,
    title: 'Otras Métricas para la Experiencia del Cliente',
    description:
      'Más allá de las métricas convencionales, aprende sobre otras herramientas de medición.',
    url: 'https://www.empati.cl/',
  },
]

import { ViewportScroller } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { PaymentFacade } from 'src/app/store/payment/payment.facade'
import { PaymentService } from '../../../services/pages/payment.service'
import { IStepPaymentForm } from './../../../store/payment/payment.state'
import {
  COMERCIAL_INFORMATION_NAME,
  PAYMENT_METHOD_ID,
  PAYMENT_METHOD_NAME,
  PERSONAL_INFORMATION_ID,
  PERSONAL_INFORMATION_NAME,
} from './../../../utils/constants/payment'

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
})
export class PaymentComponent implements OnInit {
  public paymentMethod: string
  public planId: string
  public typeSuscription: number
  public countries: any

  public PERSONAL_INFORMATION_NAME: string = PERSONAL_INFORMATION_NAME
  public COMERCIAL_INFORMATION_NAME: string = COMERCIAL_INFORMATION_NAME
  public PAYMENT_METHOD_NAME: string = PAYMENT_METHOD_NAME

  constructor(
    public paymentFacade: PaymentFacade,
    private viewportScroller: ViewportScroller,
    public activateRoute: ActivatedRoute,
    private _paymentService: PaymentService
  ) {}

  ngOnInit(): void {
    this.planId = this.activateRoute.snapshot.params.planId
    this.typeSuscription = +this.activateRoute.snapshot.params.typeSuscription
    this.getAllCountries()
  }

  public changePaymentMethod(): void {
    this.paymentMethod = 'webpay'
    this.paymentFacade.updateMethodPayment(this.paymentMethod)
    this.paymentFacade.UpdateStepById(
      PAYMENT_METHOD_ID,
      PAYMENT_METHOD_NAME,
      true
    )
  }

  public updateStep(step: IStepPaymentForm) {
    const { id, name } = step

    if (step.active) {
      this.paymentFacade.isValidForm$.subscribe((isValidForm: boolean) => {
        if (id === PAYMENT_METHOD_ID && !isValidForm) {
          this.paymentFacade.UpdateStepById(
            null,
            PERSONAL_INFORMATION_NAME,
            true
          )
          setTimeout(() => {
            this.viewportScroller.scrollToAnchor(PERSONAL_INFORMATION_ID)
          }, 100)
        } else this.paymentFacade.UpdateStepById(id, name, true)
      })
    } else this.paymentFacade.UpdateStepById(id, name, true)
  }

  public payPlan(): void {
    this.paymentFacade.isValidForm$.subscribe((isValidForm: boolean) => {
      if (!isValidForm) {
        this.paymentFacade.UpdateStepById(null, PERSONAL_INFORMATION_NAME, true)
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor(PERSONAL_INFORMATION_ID)
        }, 100)
        return
      }

      this.paymentFacade.payPlan(this.typeSuscription)
    })
  }
  public getAllCountries(): void {
    this._paymentService.getCountries().subscribe((response: any) => {
      this.countries = response.data
    })
  }
}

<nz-spin
  [nzSpinning]="loading"
  [nzSize]="'large'"
  nzTip="Procesando tu solicitud. Este proceso puede tomar un momento."
>
  <form
    *ngIf="!isSurveyGenerated"
    nz-form
    [formGroup]="form"
    class="login-form survey-form"
    (ngSubmit)="submitForm()"
  >
    <app-input-field
      class="w-100"
      [groupName]="form"
      controlName="surveyName"
      label="Nombre encuesta (*)"
      errorMessage="Ingresa nombre de encuesta"
      icon="audit"
    ></app-input-field>

    <div class="row w-100">
      <div class="col-12 col-md-6 p-0 pr-md-2">
        <app-select-field
          class="w-100"
          [groupName]="form"
          controlName="surveyCategory"
          [label]="'Categoría de encuesta  (*)'"
          [options]="categories"
          placeholder="Seleccionar opción"
          errorMessage="Selecciona una categoría de encuesta"
        ></app-select-field>
      </div>
      <div class="col-12 col-md-6 p-0 pl-md-2">
        <app-select-field
          class="w-100"
          [groupName]="form"
          [controlName]="'surveyFormat'"
          [label]="'Formato de encuesta'"
          [options]="surveyFormat"
          placeholder="Seleccionar opción"
        ></app-select-field>
      </div>
    </div>

    <app-text-area-field
      class="w-100"
      controlName="surveyDescription"
      [groupName]="form"
      label="Objetivo de la encuesta  (*)"
      placeholder="Ingresa objetivo o contexto de encuesta"
      errorMessage="Ingresa objetivo de encuesta"
      icon="audit"
    ></app-text-area-field>

    <hr class="m-0 p-0 w-100" />

    <nz-comment nzAuthor="Empati IA">
      <nz-avatar
        nz-comment-avatar
        nzIcon="user"
        [nzSrc]="IMG_EMPATI_BOT"
      ></nz-avatar>
      <nz-comment-content>
        <span class="font-weight-bold"
          >Tu Asistente IA para Crear Encuestas 🤖</span
        >
        <br />
        <p>
          Hola, soy tu Asistente IA de Empati. 🙌 Proporciona un nombre para tu
          encuesta, selecciona una categoría alineada con tus metas y si dudas
          sobre el formato, ¡estoy aquí para asesorarte! Solo necesito que
          detalles el objetivo principal de tu encuesta. Con esa información, te
          guiaré paso a paso. Te ofreceré preguntas cuidadosamente seleccionadas
          y te recomendaré el mejor formato para captar la atención de tu
          público. Haz clic en <b>'Generar con IA'</b> y observa cómo simplifico
          el proceso de diseño de tu encuesta, asegurándote una experiencia
          fluida y datos valiosos.
          <b>¡Comencemos esta aventura de datos juntos!</b> 🚀
        </p>
      </nz-comment-content>
    </nz-comment>

    <nz-comment *ngIf="isErrorExplainIA" nzAuthor="Empati IA">
      <nz-avatar
        nz-comment-avatar
        nzIcon="user"
        [nzSrc]="IMG_EMPATI_BOT"
      ></nz-avatar>
      <nz-comment-content>
        <span class="font-weight-bold">¡Vaya! Algo no cuadra 🤨</span>
        <br />
        <p>
          Al parecer tu solicitud necesita un poco más de detalle 🤔. Intenta
          proporcionar un contexto más específico y claro. ¡Así podremos
          ayudarte mejor! 🌟
        </p>
      </nz-comment-content>
    </nz-comment>

    <nz-comment *ngIf="surveyIAResult" nzAuthor="Empati IA">
      <nz-avatar
        nz-comment-avatar
        nzIcon="user"
        [nzSrc]="IMG_EMPATI_BOT"
      ></nz-avatar>
      <nz-comment-content>
        <span class="font-weight-bold">¡Hagamos tu encuesta aún mejor! 🚀</span>
        <br />
        <p>
          Para que tu encuesta sea más efectiva, asegúrate que el contexto sea
          clara y directo. Piensa en lo que realmente necesitas saber de tus
          encuestados. ¡Recuerda, las preguntas precisas generan respuestas
          valiosas! 👍
        </p>
      </nz-comment-content>
      <nz-comment-action>
        <span (click)="isSurveyGenerated = true">
          Volver a la encuesta Anterior
        </span>
      </nz-comment-action>
    </nz-comment>

    <div class="d-block mt-5">
      <div class="d-flex justify-content-end align-items-center">
        <a
          *ngIf="!loading"
          (click)="this.handleCancel.emit()"
          class="text-secondary mr-3"
        >
          <span>Cancelar</span>
        </a>

        <app-special-button
          [disabled]="loading"
          [buttonText]="nameButton"
          iconType="robot"
          (buttonClicked)="submitForm()"
        ></app-special-button>
      </div>
    </div>
  </form>

  <div class="" *ngIf="isSurveyGenerated && !loading">
    <app-survey-form-massive
      [surveyIAResult]="surveyIAResult"
      [(isSurveyGenerated)]="isSurveyGenerated"
    ></app-survey-form-massive>
  </div>
</nz-spin>

<!-- <app-loader-v2 *ngIf="loading"> </app-loader-v2> -->

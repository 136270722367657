import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  forwardRef,
} from '@angular/core'
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms'

@Component({
  selector: 'app-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFieldComponent),
      multi: true,
    },
  ],
})
export class InputFieldComponent implements OnInit, OnChanges {
  @Input() controlName: any
  @Input() groupName: FormGroup
  @Input() label: string
  @Input() errorMessage: string
  @Input() secondaryErrorMessage: string
  @Input() icon: string
  @Input() inputType = 'text'
  @Input() maxLength = 250
  @Input() disabled = false
  @Input() readOnly = false

  public isValidField: boolean
  public isMandatory: boolean
  public isTouched: boolean
  public isFocused: boolean = false
  public hasValue: boolean

  ngOnInit(): void {
    if (!this.groupName?.controls[this.controlName]) {
      console.error('InputFieldComponent: FormGroup o controlName no definido.')
      return
    }

    if (this.disabled) {
      this.groupName.get(this.controlName).disable()
    } else {
      this.groupName.get(this.controlName).valueChanges.subscribe((value) => {
        this.hasValue = value !== null && value !== ''
        this.validateInput()
      })
    }

    this.validateInput()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled) {
      const isDisabled = changes.disabled.currentValue
      if (isDisabled) {
        this.groupName.get(this.controlName).disable()
      } else {
        this.groupName.get(this.controlName).enable()
      }
    }
  }

  validateInput(): void {
    const control = this.groupName.get(this.controlName)
    if (control) {
      this.isValidField = control.valid
      this.isMandatory = control.validator !== null
      this.isTouched = control.touched
      this.hasValue = control.value !== null && control.value !== ''
    }
  }

  get validationStatus(): string {
    const control = this.groupName.get(this.controlName)
    return control && control.touched && !control.valid ? 'error' : 'success'
  }

  onBlur(): void {
    this.isFocused = false
  }

  onFocus(): void {
    this.isFocused = true
  }
}

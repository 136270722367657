<div class="timeline-responses">
  <nz-spin [nzSpinning]="responsesFacade.isLoadingLastResponses$ | async">
    <nz-timeline>
      <nz-timeline-item
        *ngFor="let r of responsesFacade.lastResponses$ | async"
      >
        <div class="timeline px-3 py-2">
          <div class="row align-items-center">
            <div class="col-5">
              <span class="d-block survey-timeline"
                ><strong>Encuesta:</strong> {{ r.survey }}</span
              >
              <span class="question-timeline">{{ r.question }}</span>
              <span class="question-date">{{
                r.responseDate | customDate
              }}</span>
            </div>
            <div class="item-responses col-7 align-self-center">
              <!-- CSAT RESPONSE -->
              <div *ngIf="r.questionTypeId === 1">
                <app-csat-response-item [csatScore]="r.response" [csatType]="1">
                </app-csat-response-item>
              </div>
              <!-- NPS RESPONSE -->
              <div *ngIf="r.questionTypeId === 2">
                <app-nps-response-item
                  [npsScore]="r.response"
                ></app-nps-response-item>
              </div>
              <!-- SELECT MULTIPLE -->
              <div class="container-multiple" *ngIf="r.questionTypeId === 3">
                <div class="selected-option" *ngFor="let item of r.response">
                  <span
                    nz-tooltip
                    [nzTooltipTrigger]="'hover'"
                    [nzTooltipPlacement]="'top'"
                    [nzTooltipTitle]="r.response"
                    >{{ item }}
                  </span>
                </div>
              </div>
              <!-- SELECTION MULTIPLE -->
              <div *ngIf="r.questionTypeId === 4">
                <div class="selected-option">
                  <span
                    nz-tooltip
                    [nzTooltipTrigger]="'hover'"
                    [nzTooltipPlacement]="'top'"
                    [nzTooltipTitle]="r.response"
                    >{{ r.response }}
                  </span>
                </div>
              </div>
              <!-- TEXT SHORT -->
              <div *ngIf="r.questionTypeId === 5">
                <div class="text-short">
                  <span
                    nz-tooltip
                    [nzTooltipTrigger]="'hover'"
                    [nzTooltipPlacement]="'top'"
                    [nzTooltipTitle]="r.response"
                    >{{ r.response }}
                  </span>
                </div>
              </div>
              <!-- TEXT LONG -->
              <div class="h-50" *ngIf="r.questionTypeId === 6">
                <div class="text-large">
                  <span
                    nz-tooltip
                    [nzTooltipTrigger]="'hover'"
                    [nzTooltipPlacement]="'top'"
                    [nzTooltipTitle]="r.response"
                    >{{ r.response }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nz-timeline-item>
      <ng-template #dotTemplate>
        <span nz-icon nzType="clock-circle-o" style="font-size: 16px"></span>
      </ng-template>
    </nz-timeline>
  </nz-spin>
</div>

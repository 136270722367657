import { Component, EventEmitter, OnInit, Output } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { NzNotificationService } from 'ng-zorro-antd/notification'
import { IResponse } from 'src/app/models/response/IResponse'
import { FormatType } from 'src/app/models/response/ISurvey'
import { IGenerateSurveyIA } from './../../../models/request/IGenerateSurveyIA'
import { IMassiveSurveyQuestionRequest } from './../../../models/request/IMassiveSurveyQuestion'
import { IAService } from './../../../services/shared/ia.service'
import {
  ERROR_SERVICE_MESSAGE,
  ERROR_SERVICE_TITLE,
} from './../../../utils/constants/generic-messages'

const CATEGORIES = [
  'Opinión de Clientes',
  'Prueba de conceptos, productos, servicios',
  'Seguimiento o reconocimiento de marca',
  'Investigación de mercado',
  'Desempeño empleados',
  'Retroalimentación general',
  'Registro para evento',
  'Investigación academica',
  'Votación',
]

export const SURVEY_FORMAT = [
  'Wizard (Paso a paso)',
  'Página única (Todo en una página)',
  'Ayúdame a decidir (Recomendador IA)',
]

@Component({
  selector: 'app-survey-form-ia',
  templateUrl: './survey-form-ia.component.html',
  styleUrls: ['./survey-form-ia.component.scss'],
})
export class SurveyFormIAComponent implements OnInit {
  @Output() handleCancel = new EventEmitter()
  public surveyTitle: string
  public loading: boolean
  public form: FormGroup
  public nameButton: string
  public categories = CATEGORIES
  public surveyFormat = SURVEY_FORMAT
  public surveyFormatType: FormatType = 'layout-1'
  public isSurveyGenerated: boolean = false
  public surveyIAResult: IMassiveSurveyQuestionRequest
  public IMG_EMPATI_BOT = '../../../../../assets/images/profile/empati-ia.png'
  public isErrorExplainIA: boolean = false

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private _iaService: IAService,
    private _notification: NzNotificationService
  ) {
    this.loading = false
  }

  ngOnInit(): void {
    this.nameButton = 'Generar con IA'
    this.surveyFormatType = 'layout-1'
    this.form = this.fb.group({
      surveyName: [null, [Validators.required]],
      surveyFormat: [null],
      surveyCategory: [null, [Validators.required]],
      surveyDescription: [null, [Validators.required]],
    })
  }

  public setFormatType(formatType: FormatType): void {
    this.surveyFormatType = formatType
  }

  public submitForm(): void {
    for (const i in this.form.controls) {
      this.form.controls[i].markAsDirty()
      this.form.controls[i].updateValueAndValidity()
    }

    if (this.form.valid) this.generateSurveyIAFetch()
  }

  private generateSurveyIAFetch(): void {
    if (this.form.valid) {
      const requestCreate: IGenerateSurveyIA = {
        surveyName: this.form.get('surveyName')?.value,
        surveyDescription: this.form.get('surveyDescription')?.value,
        surveyCategory: this.form.get('surveyCategory')?.value,
        surveyFormat: this.form.get('surveyFormat')?.value,
      }

      this.loading = true
      this._iaService.generateSurveyIA(requestCreate).subscribe(
        (response: IResponse) => {
          if (response.status.code) {
            try {
              this.surveyIAResult = response.data
              this.loading = false
              this.isSurveyGenerated = true
              return
            } catch {
              this.router.navigate([`/app/survey`])
            }
          }
        },
        (error) => {
          this.loading = false
          this.isSurveyGenerated = false
          if (error.status === 400) {
            this.isErrorExplainIA = true
          } else {
            this._notification.create(
              'error',
              ERROR_SERVICE_TITLE,
              ERROR_SERVICE_MESSAGE
            )
          }
        }
      )
    }
  }
}

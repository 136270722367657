<nz-form-item [formGroup]="groupName" class="pb-2">
  <nz-form-control [nzErrorTip]="errorMessage">
    <nz-input-group [nzPrefixIcon]="icon">
      <input
        [id]="controlName"
        [formControlName]="controlName"
        [type]="inputType"
        [maxlength]="maxLength"
        [disabled]="disabled"
        [readOnly]="readOnly"
        nz-input
        (blur)="onBlur()"
        (focus)="onFocus()"
      />
      <label [for]="controlName" [class.floating-label]="hasValue || isFocused">
        {{ label }}
      </label>
    </nz-input-group>
  </nz-form-control>
</nz-form-item>
